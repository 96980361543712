

import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {IUserService} from '@/services/dto/IUserService';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import {EnumUserServiceType} from '@/services/dto/IDtos';
import ErrorUtils from '@/utils/ErrorUtils';

@Component({
	components: {
		UserCard,
	},
})
export default class BalladeDeactivation extends AbstractComponentVue {
	private accountId: number = +this.$route.params.id;
	private balladeService: IUserService = {} as IUserService;
  private isLoading: boolean = true;

	private mounted() {
		this.loadService(this.accountId);
	}

	private loadService(id: number): void {
    services.restService.callGet('/api/v1/accounts/' + id)
        .then((res) => {
          const userResponse: IUserResponse = res.data;
          this.balladeService = {} as IUserService;
          userResponse.userServices.forEach(
              (x) => {
                if (x.serviceType === EnumUserServiceType.BALLADE) {
                  this.balladeService = x;
                }
              });
        })
        .finally(() => {
          this.isLoading = false;
        });
	}

	/**
	 * Annule la désactivation.
	 */
	private cancel(): void {
		services.routageService.goTo('/account/' + this.accountId + '/services');
	}

	/**
	 * Désactive le service ballade.
	 */
	private deactivateBallade(): void {
    this.showModal('modal-traitement-cours');
    const uri: string = '/api/v1/user-services/ballade/' + this.accountId + '/deactivate';
    services.restService.callDelete(uri)
        .then((res) => {
          this.hideModal('modal-traitement-cours');
          if (res !== undefined && res.request.status === 200) {
            AlertService.success('Le service Ballade a été désactivé avec succès');
            services.routageService.goTo('/account/' + this.accountId + '/services');
            services.loginService.ifCurrentUserReloadStore(this.accountId,
                () => this.hideModal('modal-traitement-cours'));
          } else {
            console.warn(res);
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch((respError) => {
          if (respError.request.status === 404) {
            // on affiche que l'utilisateur n'a pas été trouvé.
            AlertService.warning('L\'utilisateur concerné n\'a pas été trouvé.');
          } else if (respError.request.status === 403) {
            // Accès interdit
            AlertService.warning('Accès interdit. Vous ne disposez pas des autorisations nécessaires.');
          } else {
            AlertService.error(ErrorUtils.msgDefault);
          }
          this.hideModal('modal-traitement-cours');
        });
	}
}
